<script setup>
import {
  ref,
  inject,
  defineProps,
  onMounted,
  computed,
  defineEmits,
} from "vue";

import { useRouter } from "vue-router";

import Card from "@/baseComponents/Card";
import AlertIcon from "vue-material-design-icons/AlertCircle";
import ArrowIcon from "vue-material-design-icons/ChevronRight";
import BiodefensiveIcon from "vue-material-design-icons/Leaf";

const swal = inject("$swal");

const emit = defineEmits(["sendReport"]);

const props = defineProps({
  report: {
    type: Object,
    default: () => {
      return {};
    },
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const objReport = ref({});
const statusPeriod = ref("");

const reportId = router?.currentRoute?.value?.params?.id || "novo";

onMounted(() => {
  objReport.value = props.report;
  statusPeriod.value = props?.report?.period?.status;
});

const validateFields = () => {
  if (objReport.value.biodefensiveSales.length == 0) return salesErrorMessage();

  for (let i = 0; i < objReport.value.biodefensiveSales.length; i++) {
    const sale = objReport.value.biodefensiveSales[i];

    if (
      suplyPercentage(sale) !== 100 ||
      distributionPercentage(sale) !== 100 ||
      sale.quantity <= 0 ||
      sale.avgPrice <= 0 ||
      sale.avgRecommendedDose <= 0 ||
      sale.biodefensiveClass.length == 0 ||
      sale.organismType.length == 0 ||
      sale.physicalForm.length == 0 ||
      sale.useMode.length == 0 ||
      sale.primeTarget.length == 0
    ) {
      return salesErrorMessage();
    }

    if (
      sale.b2bsales < 100 &&
      (marketAccessPercentage(sale) !== 100 ||
        saleAccessPercentage(sale) !== 100 ||
        ufPercentage(sale) !== 100)
    ) {
      return salesErrorMessage();
    }
  }

  sendReport();
};

const sendReport = () => {
  emit("sendReport", objReport.value, "Biodefensive");
};

const calcSaleProfit = computed(() => {
  const result = (sale) => {
    if (sale.quantity > 0 && sale.avgPrice > 0 && sale.avgRecommendedDose > 0)
      return (
        (Number(sale.quantity) -
          Number(sale.devolution) -
          Number(sale.discard)) *
          Number(sale.avgPrice) || 0
      );
    else return 0;
  };
  return result;
});

const periodNameLabel = computed(() => {
  return props.report.period.name || "";
});

const suplyPercentage = (sale) => {
  return Number(
    Number(
      Number(sale.nationalSupplement) +
        Number(sale.importedSupplement) +
        Number(sale.thirdPartySupplement)
    ).toFixed(2)
  );
};

const distributionPercentage = (sale) => {
  return Number(
    Number(Number(sale.b2bsales) + Number(sale.consumerSales)).toFixed(2)
  );
};

const marketAccessPercentage = (sale) => {
  return Number(
    Number(
      Number(sale.dealer) +
        Number(sale.cooperative) +
        Number(sale.consortia) +
        Number(sale.producer)
    ).toFixed(2)
  );
};

const saleAccessPercentage = (sale) => {
  return Number(
    Number(
      Number(sale.soy) +
        Number(sale.bean) +
        Number(sale.corn) +
        Number(sale.wheat) +
        Number(sale.rice) +
        Number(sale.pasture) +
        Number(sale.cotton) +
        Number(sale.forest) +
        Number(sale.vegetable) +
        Number(sale.fruit) +
        Number(sale.sugarCane) +
        Number(sale.peanut) +
        Number(sale.coffee) +
        Number(sale.ornamental) +
        Number(sale.otherGrains) +
        Number(sale.otherCulture)
    ).toFixed(2)
  );
};

const ufPercentage = (sale) => {
  return Number(
    Number(
      Number(sale.ac) +
        Number(sale.al) +
        Number(sale.am) +
        Number(sale.ap) +
        Number(sale.ba) +
        Number(sale.ce) +
        Number(sale.df) +
        Number(sale.es) +
        Number(sale.go) +
        Number(sale.ma) +
        Number(sale.mg) +
        Number(sale.ms) +
        Number(sale.mt) +
        Number(sale.pa) +
        Number(sale.pb) +
        Number(sale.pe) +
        Number(sale.pi) +
        Number(sale.pr) +
        Number(sale.rj) +
        Number(sale.rn) +
        Number(sale.ro) +
        Number(sale.rr) +
        Number(sale.rs) +
        Number(sale.sc) +
        Number(sale.se) +
        Number(sale.sp) +
        Number(sale.to) +
        Number(sale.export)
    ).toFixed(2)
  );
};

const salesErrorMessage = () => {
  swal.fire({
    icon: "warning",
    title: "Campos com erros",
    text: "por favor, verifique se todos os campos foram preenchidos corretamente.",
  });
};
</script>

<template>
  <div>
    <div class="inputs-holder desktop12 tablet12 mobile12">
      <div class="flex flex-between desktop12 tablet12 mobile12">
        <div class="desktop5 tablet6 flex-between">
          <div class="input-item desktop12 tablet12 highlight-input">
            <span>Período das vendas *</span>
            <div class="input-holder">
              <input type="text" :value="periodNameLabel" disabled />
            </div>
          </div>
        </div>
        <div class="details-holder">
          <div class="sales-category-type mt-1-mobile">
            <BiodefensiveIcon :size="36" fillColor="var(--primary)" />
            <span>Biodefensivos</span>
          </div>
          <div
            v-if="reportId !== 'novo'"
            class="report-status mt-1-mobile"
            :class="{
              alteration: objReport.status == 'Alteração necessária',
              onapproved:
                objReport.status == 'Aprovado' &&
                statusPeriod == 'Em andamento ANPII',
              approved:
                objReport.status == 'Aprovado' &&
                report.period.status == 'Aprovado',
              disapproved: objReport.status == 'Reprovado',
            }"
          >
            <div class="circle">
              <span v-show="objReport.status == 'Alteração necessária'">!</span>
            </div>
            <span
              v-if="
                objReport.status == 'Aprovado' &&
                statusPeriod === 'Em andamento 5P2R'
              "
            >
              Em análise
            </span>
            <span
              v-else-if="
                objReport.status == 'Aprovado' &&
                statusPeriod === 'Em andamento ANPII'
              "
            >
              Em aprovação
            </span>
            <span v-else>{{ objReport.status }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        objReport &&
        objReport.message &&
        (objReport.status == 'Alteração necessária' ||
          objReport.status == 'Reprovado')
      "
      class="my-2 observation-holder"
    >
      <AlertIcon fillColor="#df3333" :size="28" />
      <p>{{ objReport.message }}</p>
    </div>
    <Card
      v-for="sale in objReport.biodefensiveSales"
      :key="sale.id"
      class="intern-card mt-2"
    >
      <div class="inputs-holder mt-0">
        <div class="input-item highlight-input">
          <span>Classe</span>
          <AlertIcon
            fillColor="#E45A2E"
            :size="18"
            v-show="sale.biodefensiveClass.length == 0"
          />
          <div class="input-holder">
            <input
              type="text"
              v-model="sale.biodefensiveClass"
              :title="sale.biodefensiveClass"
              disabled
              :style="
                sale.biodefensiveClass.length > 0
                  ? ''
                  : 'border: 2px solid #e45a2e;border-radius: 0.6em;'
              "
            />
          </div>
        </div>
        <div class="input-item highlight-input">
          <span>Tipo Organismo/Ativo</span>
          <AlertIcon
            fillColor="#E45A2E"
            :size="18"
            v-show="sale.organismType.length == 0"
          />
          <div class="input-holder">
            <input
              type="text"
              v-model="sale.organismType"
              disabled
              :style="
                sale.organismType.length > 0
                  ? ''
                  : 'border: 2px solid #e45a2e;border-radius: 0.6em;'
              "
            />
          </div>
        </div>
        <div class="input-item highlight-input">
          <span :style="sale.physicalForm.length > 0 ? '' : 'color: #e45a2e'">
            Forma física/Formulação
          </span>
          <AlertIcon
            fillColor="#E45A2E"
            :size="18"
            v-show="sale.physicalForm.length == 0"
          />
          <div class="input-holder">
            <input
              type="text"
              v-model="sale.physicalForm"
              disabled
              :style="
                sale.physicalForm.length > 0
                  ? ''
                  : 'border: 2px solid #e45a2e;border-radius: 0.6em;'
              "
            />
          </div>
        </div>
        <div class="input-item highlight-input">
          <span :style="sale.useMode.length > 0 ? '' : 'color: #e45a2e'">
            Modo de uso
          </span>
          <AlertIcon
            fillColor="#E45A2E"
            :size="18"
            v-show="sale.useMode.length == 0"
          />
          <div class="input-holder">
            <input
              type="text"
              v-model="sale.useMode"
              disabled
              :style="
                sale.useMode.length > 0
                  ? ''
                  : 'border: 2px solid #e45a2e;border-radius: 0.6em;'
              "
            />
          </div>
        </div>
        <div class="input-item highlight-input">
          <span :style="sale.primeTarget.length > 0 ? '' : 'color: #e45a2e'">
            Alvo principal
          </span>
          <AlertIcon
            fillColor="#E45A2E"
            :size="18"
            v-show="sale.primeTarget.length == 0"
          />
          <div class="input-holder">
            <input
              type="text"
              v-model="sale.primeTarget"
              disabled
              :style="
                sale.primeTarget.length > 0
                  ? ''
                  : 'border: 2px solid #e45a2e;border-radius: 0.6em;'
              "
            />
          </div>
        </div>
      </div>

      <div class="divider mt-2"></div>

      <div class="inputs-holder mt-3 mt-2-mobile">
        <div v-if="calcSaleProfit(sale) != 0" class="title animated fadeIn">
          <span>Vendas</span>
          <span>{{ $filters.currencyFilter(calcSaleProfit(sale)) }}</span>
        </div>
        <div v-else class="title animated fadeIn">
          <span style="color: #e45a2e">Vendas</span>
          <AlertIcon fillColor="#E45A2E" style="display: flex" />
          <span style="color: #e45a2e">{{
            $filters.currencyFilter(calcSaleProfit(sale))
          }}</span>
          <span class="mobile-hidden" style="color: #e45a2e"
            >valor não calculado por falta de dados</span
          >
          <span class="desktop-hidden tablet-hidden" style="color: #e45a2e"
            >falta dados</span
          >
        </div>
        <div class="input-item">
          <span :style="sale.quantity > 0 ? '' : 'color: #e45a2e'"
            >Volume de entregas</span
          >
          <div class="input-holder">
            <input
              :style="
                sale.quantity > 0
                  ? 'padding-right: 60px;'
                  : 'padding-right: 60px;border: 2px solid #e45a2e;border-radius: 0.6em;'
              "
              type="number"
              v-model="sale.quantity"
              :disabled="!canEdit"
            />
            <div class="unit-holder plus-min">
              <div class="line"></div>
              <span>L ou kg</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span>Vol. de devoluções (L ou kg)</span>
          <div class="input-holder">
            <input
              style="padding-right: 60px"
              type="number"
              v-model="sale.devolution"
              :disabled="!canEdit"
            />
            <div class="unit-holder plus-min">
              <div class="line"></div>
              <span>L ou kg</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span>Vol. de descarte (L ou kg)</span>
          <div class="input-holder">
            <input
              style="padding-right: 60px"
              type="number"
              v-model="sale.discard"
              :disabled="!canEdit"
            />
            <div class="unit-holder plus-min">
              <div class="line"></div>
              <span>L ou kg</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span :style="sale.avgRecommendedDose > 0 ? '' : 'color: #e45a2e'"
            >Dose média recomendada (L ou kg/ha)</span
          >
          <div class="input-holder">
            <input
              :style="
                sale.avgRecommendedDose > 0
                  ? 'padding-right: 80px;'
                  : 'padding-right: 80px;border: 2px solid #e45a2e;border-radius: 0.6em;'
              "
              type="number"
              v-model="sale.avgRecommendedDose"
              :disabled="!canEdit"
            />
            <div class="unit-holder plus-max">
              <div class="line"></div>
              <span>L ou kg/ha</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span :style="sale.avgPrice > 0 ? '' : 'color: #e45a2e'"
            >Preço médio de vendas (R$/L ou kg)</span
          >
          <div class="input-holder">
            <input
              :style="
                sale.avgPrice > 0
                  ? 'padding-right: 80px;'
                  : 'padding-right: 80px;border: 2px solid #e45a2e;border-radius: 0.6em;'
              "
              type="number"
              v-model="sale.avgPrice"
              :disabled="!canEdit"
            />
            <div class="unit-holder plus-max">
              <div class="line"></div>
              <span>R$/L ou kg</span>
            </div>
          </div>
        </div>
      </div>

      <div class="divider mt-2"></div>

      <div class="inputs-holder mt-3 mt-2-mobile">
        <div v-if="suplyPercentage(sale) === 100" class="title animated fadeIn">
          <span>Suprimento</span>
          <span>{{ suplyPercentage(sale) }}%</span>
        </div>
        <div v-else class="title animated fadeIn">
          <span style="color: #e45a2e">Suprimento</span>
          <AlertIcon fillColor="#E45A2E" style="display: flex" />
          <span style="color: #e45a2e">{{ suplyPercentage(sale) }}%</span>
          <span class="mobile-hidden" style="color: #e45a2e"
            >a totalização tem que ser 100%</span
          >
          <span class="desktop-hidden tablet-hidden" style="color: #e45a2e"
            >necessário 100%</span
          >
        </div>
        <div class="input-item">
          <span>Produção própria nacional (% volume em L ou kg)</span>
          <div class="input-holder">
            <input
              type="number"
              v-model="sale.nationalSupplement"
              :disabled="!canEdit"
              step="0"
            />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span>Importação (% volume em L ou kg)</span>
          <div class="input-holder">
            <input
              type="number"
              v-model="sale.importedSupplement"
              :disabled="!canEdit"
            />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span>Produção em terceiros nacional (% volume em L ou kg)</span>
          <div class="input-holder">
            <input
              type="number"
              v-model="sale.thirdPartySupplement"
              :disabled="!canEdit"
            />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="divider mt-2"></div>

      <div class="inputs-holder mt-3 mt-2-mobile">
        <div
          v-if="distributionPercentage(sale) === 100"
          class="title animated fadeIn"
        >
          <span>Distribuição</span>
          <span>{{ distributionPercentage(sale) }}%</span>
        </div>
        <div v-else class="title animated fadeIn">
          <span style="color: #e45a2e">Distribuição</span>
          <AlertIcon fillColor="#E45A2E" style="display: flex" />
          <span style="color: #e45a2e"
            >{{ distributionPercentage(sale) }}%</span
          >
          <span class="mobile-hidden" style="color: #e45a2e"
            >a totalização tem que ser 100%</span
          >
          <span class="desktop-hidden tablet-hidden" style="color: #e45a2e"
            >necessário 100%</span
          >
        </div>
        <div class="input-item">
          <span>Vendas B2B (% valor de faturamento)</span>
          <div class="input-holder">
            <input type="number" v-model="sale.b2bsales" :disabled="!canEdit" />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span>Vendas Mercado consumidor (%)</span>
          <div class="input-holder">
            <input
              type="number"
              v-model="sale.consumerSales"
              :disabled="!canEdit"
            />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="divider mt-2"></div>

      <div class="inputs-holder mt-3 mt-2-mobile">
        <div
          v-if="marketAccessPercentage(sale) === 100 || sale.b2bsales === 100"
          class="title animated fadeIn"
        >
          <span>Canais de acesso ao mercado</span>
          <span>{{ marketAccessPercentage(sale) }}%</span>
        </div>
        <div v-else class="title animated fadeIn">
          <span style="color: #e45a2e">Canais de acesso ao mercado</span>
          <AlertIcon fillColor="#E45A2E" style="display: flex" />
          <span style="color: #e45a2e"
            >{{ marketAccessPercentage(sale) }}%</span
          >
          <span class="mobile-hidden" style="color: #e45a2e"
            >a totalização tem que ser 100%</span
          >
          <span class="desktop-hidden tablet-hidden" style="color: #e45a2e"
            >necessário 100%</span
          >
        </div>
        <div class="input-item">
          <span>Revendas (% valor das vendas ao mercado)</span>
          <div class="input-holder">
            <input type="number" v-model="sale.dealer" :disabled="!canEdit" />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span>Cooperativas (%)</span>
          <div class="input-holder">
            <input
              type="number"
              v-model="sale.cooperative"
              :disabled="!canEdit"
            />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span>Pools e Consorcios (%)</span>
          <div class="input-holder">
            <input
              type="number"
              v-model="sale.consortia"
              :disabled="!canEdit"
            />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
        <div class="input-item">
          <span>Venda direta para produtores (%)</span>
          <div class="input-holder">
            <input type="number" v-model="sale.producer" :disabled="!canEdit" />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="divider mt-2"></div>

      <div class="inputs-holder mt-3 mt-2-mobile">
        <div
          v-if="saleAccessPercentage(sale) === 100 || sale.b2bsales === 100"
          class="title animated fadeIn"
        >
          <span>Culturas das vendas ao mercado</span>
          <span>{{ saleAccessPercentage(sale) }}%</span>
        </div>
        <div v-else class="title animated fadeIn">
          <span style="color: #e45a2e">Culturas das vendas ao mercado</span>
          <AlertIcon fillColor="#E45A2E" style="display: flex" />
          <span style="color: #e45a2e">{{ saleAccessPercentage(sale) }}%</span>
          <span class="mobile-hidden" style="color: #e45a2e"
            >a totalização tem que ser 100%</span
          >
          <span class="desktop-hidden tablet-hidden" style="color: #e45a2e"
            >necessário 100%</span
          >
        </div>
        <div class="sale-inputs-holder">
          <div class="input-item">
            <span>Soja (% valor das vendas ao mercado)</span>
            <div class="input-holder">
              <input type="number" v-model="sale.soy" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Feijão</span>
            <div class="input-holder">
              <input type="number" v-model="sale.bean" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Milho</span>
            <div class="input-holder">
              <input type="number" v-model="sale.corn" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Trigo</span>
            <div class="input-holder">
              <input type="number" v-model="sale.wheat" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Arroz</span>
            <div class="input-holder">
              <input type="number" v-model="sale.rice" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Pastagens/Forrageiras</span>
            <div class="input-holder">
              <input
                type="number"
                v-model="sale.pasture"
                :disabled="!canEdit"
              />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Algodão</span>
            <div class="input-holder">
              <input type="number" v-model="sale.cotton" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Floresta</span>
            <div class="input-holder">
              <input type="number" v-model="sale.forest" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Hortaliças</span>
            <div class="input-holder">
              <input
                type="number"
                v-model="sale.vegetable"
                :disabled="!canEdit"
              />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Frutas</span>
            <div class="input-holder">
              <input type="number" v-model="sale.fruit" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Cana</span>
            <div class="input-holder">
              <input
                type="number"
                v-model="sale.sugarCane"
                :disabled="!canEdit"
              />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Amendoim</span>
            <div class="input-holder">
              <input type="number" v-model="sale.peanut" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Café</span>
            <div class="input-holder">
              <input type="number" v-model="sale.coffee" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Ornamentais</span>
            <div class="input-holder">
              <input
                type="number"
                v-model="sale.ornamental"
                :disabled="!canEdit"
              />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Outros grãos</span>
            <div class="input-holder">
              <input
                type="number"
                v-model="sale.otherGrains"
                :disabled="!canEdit"
              />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Outras</span>
            <div class="input-holder">
              <input
                type="number"
                v-model="sale.otherCulture"
                :disabled="!canEdit"
              />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="divider mt-2"></div>

      <div class="inputs-holder mt-3 mt-2-mobile">
        <div
          v-if="ufPercentage(sale) === 100 || sale.b2bsales === 100"
          class="title animated fadeIn"
        >
          <span>UF das vendas ao mercado</span>
          <span>{{ ufPercentage(sale) }}%</span>
        </div>
        <div v-else class="title animated fadeIn">
          <span style="color: #e45a2e">UF das vendas ao mercado</span>
          <AlertIcon fillColor="#E45A2E" style="display: flex" />
          <span style="color: #e45a2e">{{ ufPercentage(sale) }}%</span>
          <span class="mobile-hidden" style="color: #e45a2e"
            >a totalização tem que ser 100%</span
          >
          <span class="desktop-hidden tablet-hidden" style="color: #e45a2e"
            >necessário 100%</span
          >
        </div>
        <div class="sale-inputs-holder">
          <div class="input-item">
            <span>AC (% valor das vendas ao mercado)</span>
            <div class="input-holder">
              <input type="number" v-model="sale.ac" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>AL</span>
            <div class="input-holder">
              <input type="number" v-model="sale.al" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>AM</span>
            <div class="input-holder">
              <input type="number" v-model="sale.am" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>AP</span>
            <div class="input-holder">
              <input type="number" v-model="sale.ap" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>BA</span>
            <div class="input-holder">
              <input type="number" v-model="sale.ba" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>CE</span>
            <div class="input-holder">
              <input type="number" v-model="sale.ce" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>DF</span>
            <div class="input-holder">
              <input type="number" v-model="sale.df" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>ES</span>
            <div class="input-holder">
              <input type="number" v-model="sale.es" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>GO</span>
            <div class="input-holder">
              <input type="number" v-model="sale.go" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>MA</span>
            <div class="input-holder">
              <input type="number" v-model="sale.ma" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>MG</span>
            <div class="input-holder">
              <input type="number" v-model="sale.mg" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>MS</span>
            <div class="input-holder">
              <input type="number" v-model="sale.ms" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>MT</span>
            <div class="input-holder">
              <input type="number" v-model="sale.mt" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>PA</span>
            <div class="input-holder">
              <input type="number" v-model="sale.pa" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>PB</span>
            <div class="input-holder">
              <input type="number" v-model="sale.pb" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>PE</span>
            <div class="input-holder">
              <input type="number" v-model="sale.pe" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>PI</span>
            <div class="input-holder">
              <input type="number" v-model="sale.pi" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>PR</span>
            <div class="input-holder">
              <input type="number" v-model="sale.pr" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>RJ</span>
            <div class="input-holder">
              <input type="number" v-model="sale.rj" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>RN</span>
            <div class="input-holder">
              <input type="number" v-model="sale.rn" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>RO</span>
            <div class="input-holder">
              <input type="number" v-model="sale.ro" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>RR</span>
            <div class="input-holder">
              <input type="number" v-model="sale.rr" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>RS</span>
            <div class="input-holder">
              <input type="number" v-model="sale.rs" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>SC</span>
            <div class="input-holder">
              <input type="number" v-model="sale.sc" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>SE</span>
            <div class="input-holder">
              <input type="number" v-model="sale.se" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>SP</span>
            <div class="input-holder">
              <input type="number" v-model="sale.sp" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>TO</span>
            <div class="input-holder">
              <input type="number" v-model="sale.to" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
          <div class="input-item">
            <span>Exportação</span>
            <div class="input-holder">
              <input type="number" v-model="sale.export" :disabled="!canEdit" />
              <div class="unit-holder">
                <div class="line"></div>
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>

    <Card class="intern-card mt-2">
      <div class="inputs-holder mt-2 mt-0-mobile">
        <div class="title first-line">
          <span>Expectativa para os próximos 12 meses</span>
        </div>
        <div class="input-item">
          <span>Crescimento/Queda na venda de biodefensivos (%)</span>
          <div class="input-holder">
            <input
              type="number"
              v-model="objReport.growthExpectation"
              :disabled="!canEdit"
            />
            <div class="unit-holder">
              <div class="line"></div>
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="divider mt-2"></div>

      <div class="title-questions mt-2">
        <span>Perguntas abertas</span>
      </div>
      <div
        class="inputs-holder mt-2"
        v-for="(item, index) in objReport.customQuestions"
        :key="index"
      >
        <div class="input-item desktop12">
          <span>{{ item.question }}</span>
          <div>
            <textarea v-model="item.answer" :disabled="!canEdit"></textarea>
          </div>
        </div>
      </div>
    </Card>
    <div v-show="canEdit" class="flex-end mt-2">
      <div @click="validateFields" class="btn icon ml-auto">
        <span>Salvar e enviar</span>
        <ArrowIcon :size="30" fillColor="#fff" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.observation-holder {
  display: flex;
  align-items: flex-start;
  gap: 1.2rem;
  p {
    color: #df3333;
  }
}
.card-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    gap: 20px;
  }
  img {
    position: absolute;
    top: -65px;
    object-fit: cover;
  }
  .title {
    margin-left: 120px;
    @media only screen and (max-width: 720px) {
      margin-left: 0px;
      margin-right: auto;
    }
  }
}

.sales-category-type {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: fit-content;
  span {
    font-size: 1.3em;
    color: var(--primary);
  }
}

.intern-card {
  box-shadow: none !important;
  border: 1px solid #dadada;
  .highlight-input {
    span {
      color: var(--primary);
      font-family: fontRegular;
    }
    input {
      color: var(--primary);
    }
  }
  .title {
    position: absolute;
    top: -45px;
    left: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    @media only screen and (max-width: 720px) {
      position: relative;
      top: 0;
      flex-wrap: wrap;
    }
    span {
      color: var(--primary);
    }
    :first-child {
      font-family: fontLight;
    }
    :nth-child(2),
    :nth-child(3) {
      font-family: fontMedium;
      font-size: 1.4em;
      @media only screen and (max-width: 720px) {
        font-size: 1.1em;
      }
    }
    &.first-line {
      top: -40px;
      @media only screen and (max-width: 720px) {
        top: 0;
      }
    }
  }
}

.title-questions {
  position: relative;
  span {
    font-family: fontLight;
    color: var(--primary);
  }
}

textarea {
  position: relative;
  width: 100%;
  height: 80px;
  margin-top: 3px;
  padding: 10px 40px 10px 15px;
  resize: none;
  font-family: fontRegular;
  font-size: 1.1em;
  background-color: #f0f0f0;
  border-radius: 0.6em;
}

.details-holder {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  gap: 18px;
}

.report-status {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
  &.alteration {
    .circle {
      background-color: var(--orange);
    }
    span {
      color: var(--orange);
    }
  }
  &.onapproved {
    .circle {
      background-color: #4000e2;
    }
    span {
      color: #4000e2;
    }
  }
  &.approved {
    .circle {
      background-color: var(--secondary);
    }
    span {
      color: var(--secondary);
    }
  }
  &.disapproved {
    .circle {
      background-color: var(--red2);
    }
    span {
      color: var(--red2);
    }
  }
  .circle {
    position: relative;
    width: 1.1rem;
    height: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: var(--light-blue);
    span {
      color: #fff;
      z-index: 99;
      font-size: 13px;
      font-family: fontBold;
    }
  }
  span {
    color: var(--light-blue);
  }
  .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.sale-inputs-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2em 3em;
  align-items: center;
  margin-top: 37px;
  align-items: flex-end;
  @media only screen and (max-width: 720px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 0;
  }
}

@media only screen and (min-width: 721px) {
  .flex-between {
    position: relative;
    width: 100%;
    display: flex;

    justify-content: space-between;
    gap: 30px;
  }
}
</style>
